<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        @if (showAvatar && user.avatar) {
            <img class="h-7 w-7 rounded-full" [src]="user.avatar" />
        }
        @if (!showAvatar || !user.avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
        <span
            class="absolute bottom-0 right-0 h-2 w-2 rounded-full"
            [ngClass]="{
                'mb-px mr-px': !showAvatar || !user.avatar,
                'bg-green-500': user.status === 'online',
                'bg-amber-500': user.status === 'away',
                'bg-red-500': user.status === 'busy',
                'bg-gray-400': user.status === 'not-visible',
            }"
        ></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Conectado/a como</span>
            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button (click)="goToProfile(false)" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Perfil</span>
    </button>
    <button (click)="goToProfile(true)" mat-menu-item *ngIf="accessGranted">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Configuración</span>
    </button>
    
    <button mat-menu-item [matMenuTriggerFor]="userPromoter" *ngIf="accessPromoter">
        <mat-icon
            [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"
        ></mat-icon>
        <span>Promotora</span>
    </button>
    
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon
            [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
        ></mat-icon>
        <span>Cerrar sesión</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userPromoter="matMenu">
    <button mat-menu-item (click)="updateUserPromoter(item)" *ngFor="let item of promotersUser">
        <span>{{item?.name}}</span>
        <span class="ml-3 inline-flex h-2 w-2 rounded-full bg-primary" *ngIf="item?.id == promoterUserActive?.id"></span>
    </button>
</mat-menu>
