import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'app-redirect',
    template: ''
})
export class RedirectComponent implements OnInit {
    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit(): void {
        if (this.authService.check()) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/sign-in']);
        }
    }
}

const authRoutes = [
    { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
    { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
];

const adminRoutes = [
    { path: 'dashboard', loadChildren: () => import('./modules/admin/home/home.routes') },
    { path: 'profile', loadChildren: () => import('./modules/admin/profile/profile.routes') },
    { path: 'users', loadChildren: () => import('./modules/admin/users/users.routes') },
    { path: 'promoters', loadChildren: () => import('./modules/admin/promoters/promoters.routes') },
    { path: 'venues', loadChildren: () => import('./modules/admin/venues/venues.routes') },
    { path: 'tools', loadChildren: () => import('./modules/admin/tools/tools.routes') },
    { path: 'events', loadChildren: () => import('./modules/admin/events/events.routes') },
    { path: 'type-of-events', loadChildren: () => import('./modules/admin/others/type-of-events/type-of-events.routes') },
    { path: 'ticket-management', loadChildren: () => import('./modules/admin/ticket-management/ticket-management.routes') },
];

const appRoutes: Routes = [
    { 
        path: '', 
        component: RedirectComponent 
    },
    { 
        path: 'signed-in-redirect', 
        pathMatch: 'full', 
        redirectTo: 'dashboard' 
    },

    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: authRoutes,
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        resolve: { initialData: initialDataResolver },
        children: adminRoutes,
    },
    { 
        path: '**', 
        redirectTo: 'dashboard' 
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    declarations: [RedirectComponent]
})
export class AppRoutingModule {}

export { appRoutes };
