import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';

import { Subject, takeUntil } from 'rxjs';

import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {

    private _defaultNavigation: FuseNavigationItem[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    user: User;

    constructor(
        private _userService: UserService,
        private _fuseMockApiService: FuseMockApiService
    ) {
        this.registerHandlers();
        this.getUser();
    }

    getUser(): void {
        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
            this.user = user;
            this._defaultNavigation = [];

            this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id   : 'dashboard',
                    title: 'Dashboard',
                    type : 'basic',
                    icon : 'heroicons_outline:chart-pie',
                    link : '/dashboard'
                },
            ];

            if(this._userService.hasRoles(this.user, [1])) {
                this._defaultNavigation = [
                    ...this._defaultNavigation,
                    {
                        id   : 'users',
                        title: 'Usuarios',
                        type : 'basic',
                        icon : 'heroicons_outline:user-group',
                        link : '/users'
                    },
                ];
            }

            this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id   : 'promoter',
                    title: 'Promotoras',
                    type : 'basic',
                    icon : 'heroicons_outline:briefcase',
                    link : '/promoters'
                },
            ];

            this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id   : 'venue',
                    title: 'Recintos',
                    type : 'basic',
                    icon : 'mat_outline:other_houses',
                    link : '/venues'
                },
            ];

            this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id   : 'event',
                    title: 'Eventos',
                    type : 'basic',
                    icon : 'heroicons_outline:calendar-days',
                    link : '/events'
                },
            ];

            

            if(this._userService.hasRoles(this.user, [1])) {
                this._defaultNavigation = [
                    ...this._defaultNavigation,
                    {
                        id: 'otros',
                        title: 'Otros',
                        type: 'collapsable',
                        icon: 'heroicons_outline:pencil-square',
                        children: [
                            {
                                id: 'apps.help-center.home',
                                title: 'Tipo de eventos',
                                type: 'basic',
                                link: '/type-of-events',
                                exactMatch: true,
                            },
                        ],
                    },
                ];
            }

            this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id: 'ticket-management',
                    title: 'Gestión de entradas',
                    type: 'basic',
                    icon: 'heroicons_outline:ticket',
                    link : '/ticket-management'
                },
            ];

            /*this._defaultNavigation = [
                ...this._defaultNavigation,
                {
                    id   : 'event',
                    title: 'Soporte',
                    type : 'basic',
                    icon : 'heroicons_outline:lifebuoy',
                    link : '/tools/support'
                },
            ];*/
            
        });

    }

    registerHandlers(): void {
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            return [
                200, { default: cloneDeep(this._defaultNavigation)}
            ];
        });
    }
}
