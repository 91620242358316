import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Client } from '../services/apiClients';
import {environment} from "../environments/environment";
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common'; 

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers:[Client],
    standalone: true,
    imports: [RouterOutlet, CommonModule],
})
export class AppComponent implements OnInit {  

    production: boolean = false;

    constructor(
        private apiClient: Client,
        private _location: Location
    ) {
        _location.onUrlChange((url, state) => {
            if (this.isTokenExpiringSoon()) {
                console.log('El token expira en menos de 2 minutos');
                // HAGO REFRESH
                this.apiClient.authRefresh().subscribe((data: any) => {
                    localStorage.setItem('accessToken', data.access_token);
                    const expirationDate = new Date().getTime() + data.expires_in * 1000;
                    localStorage.setItem('accessTokenExpires', expirationDate.toString());
                }, (error: any) => {
                    console.log(error);
                });
            }

        });
    }

    ngOnInit() {
        
        if(environment.production){
            this.production = environment.production;
        }

    }

    isTokenExpiringSoon(): boolean {
        const expirationTimestamp = localStorage.getItem('accessTokenExpires');

        if (!expirationTimestamp) {
            // Si no hay fecha de expiración, asumimos que el token no es válido
            return false;
        }

        const now = new Date().getTime();
        const expirationDate = +expirationTimestamp; // Convertir el string a número
        const timeRemaining = expirationDate - now;

        // 2 minutos en milisegundos es 120000 ms
        const twoMinutesInMilliseconds = 2 * 60 * 1000;

        // Si el tiempo restante es menor que 2 minutos, devolvemos true
        return timeRemaining <= twoMinutesInMilliseconds;
    }

  }